import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  enterPage() {
    window.alert('Ας πούμε τώρα ότι είσαι μέλος και καλά!!');
  }

  constructor() { }

  ngOnInit() {
  }

}
