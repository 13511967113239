import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-classroom-image-student-start',
  templateUrl: './classroom-image-student-start.component.html',
  styleUrls: ['./classroom-image-student-start.component.css']
})
export class ClassroomImageStudentStartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
