import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { StudentStarterComponent } from './student-starter/student-starter.component';
import { DemoComponent } from './demo/demo.component';
import { AppRoutingModule} from './app-routing.module';
import { LeftSideComponent } from './left-side/left-side.component';
import { ProfileComponent } from './profile/profile.component';
import { ClassroomImageStudentStartComponent } from './classroom-image-student-start/classroom-image-student-start.component';
import { StudentDevicesComponent } from './student-devices/student-devices.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    StudentStarterComponent,
    DemoComponent,
    LeftSideComponent,
    ProfileComponent,
    ClassroomImageStudentStartComponent,
    StudentDevicesComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent },
      { path: 'studentStarter', component:  StudentStarterComponent },
      ]),
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
